import React, { FC, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	FormControl,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styles from './SettingsEvalForm.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { feedback, updateProfileEval } from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import PlayTextButton from '../PlayTextButton/PlayTextButton';
import {
	CALL_TIME,
	DEF_FEEDBACK_ACTIVATE,
	DEF_VOICE,
	NEGATIVE_FEEDBACK,
	POSITIVE_FEEDBACK,
	RECORDING,
	REJECT_TIME,
	RETRY_CALLS,
	STYLE_TIME,
	TASKS_TEXT_FEEDBACK,
} from '../../utils/const';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import { setBalanceModal } from '../../store/reducers/ModalSlice';
import ReactDOM from 'react-dom';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SettingsEvalFormProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};

const SettingsEvalForm: FC<SettingsEvalFormProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const voiceValue: string =
		typeof profile?.voice_feedback === 'string'
			? profile?.voice_feedback
			: DEF_VOICE;

	const initTasksText = () => {
		if (profile?.tasks_text_feedback) return profile?.tasks_text_feedback;
		return TASKS_TEXT_FEEDBACK;
	};
	const initPositiveFeedback = () => {
		if (profile?.positive_feedback) return profile?.positive_feedback;
		return POSITIVE_FEEDBACK;
	};
	const initNegativeFeedback = () => {
		if (profile?.negative_feedback) return profile?.negative_feedback;
		return NEGATIVE_FEEDBACK;
	};
	const initRecording = () => {
		if (profile?.recording_feedback === false)
			return profile?.recording_feedback;
		return RECORDING;
	};
	const initFeedback = () => {
		if (profile?.feedbackEnable === true) return profile?.feedbackEnable;
		return DEF_FEEDBACK_ACTIVATE;
	};

	const playSendValue: string =
		typeof profile?.play_send_email === 'boolean'
			? `${profile?.play_send_email}`
			: 'false';

	const playLikeValue: string =
		typeof profile?.play_like_text === 'boolean'
			? `${profile?.play_like_text}`
			: 'false';
	const initCallTime: string =
		typeof profile?.callTime === 'string' ? `${profile?.callTime}` : CALL_TIME;
	const initRejectTime: string =
		typeof profile?.rejectTime === 'string'
			? `${profile?.rejectTime}`
			: REJECT_TIME;
	const initRetryCalls: string =
		typeof profile?.retry_call_shop_feedback === 'string'
			? `${profile?.retry_call_shop_feedback}`
			: RETRY_CALLS;
	const initStyleTime: string =
		typeof profile?.styleTime === 'string'
			? `${profile?.styleTime}`
			: STYLE_TIME;

	const initStartDate = () => {
		if (profile?.startDate) {
			const now = new Date();
			const fromBase = new Date(profile?.startDate);
			fromBase.setHours(now.getHours());
			fromBase.setMinutes(now.getMinutes());
			fromBase.setSeconds(now.getSeconds());
			return fromBase;
		}
		return new Date();
	};
	const initWeekdays = () => {
		if (profile?.weekdays) return profile?.weekdays;
		return [];
	};

	const [name, setName] = useState<string>(profile?.name || '');
	const [tasksText, setTasksText] = useState<string>(initTasksText);
	const [positiveFeedback, setPositiveFeedback] =
		useState<string>(initPositiveFeedback);
	const [negativeFeedback, setNegativeFeedback] =
		useState<string>(initNegativeFeedback);
	const [url, setUrl] = useState<string>(profile?.url || '');
	const [token, setToken] = useState<string>(profile?.token_access || '');
	const [retryCall, setRetryCall] = useState<string>(initRetryCalls);
	const [email, setEmail] = useState<string>(playSendValue);
	const [likeText, setLikeText] = useState<string>(playLikeValue);
	const [voice, setVoice] = useState<string>(voiceValue);
	const [disabledPlayButton, setPlayButton] = useState<boolean>(true);
	const [callTime, setCallTime] = useState<string>(initCallTime);
	const [rejectTime, setRejectTime] = useState<string>(initRejectTime);
	const [recording, setRecording] = useState<boolean>(initRecording);
	const [styleTime, setStyleTime] = useState<string>(initStyleTime);
	const [startDate, setStartDate] = useState<string | Date | null>(
		initStartDate
	);
	const [weekdays, setWeekdays] = useState<string[]>(initWeekdays);
	const [feedbackEnable, setFeedbackEnable] = useState<boolean>(initFeedback);
	const dispatch = useAppDispatch();
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChangeAccordion =
		(panel: string) => (evt: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const setDefaultSettings = () => {
		// setGmt(GMT);
		setCallTime(CALL_TIME);
		setRetryCall(RETRY_CALLS);
		setRejectTime(REJECT_TIME);
		setStyleTime(STYLE_TIME);
		setStartDate(new Date());
		setWeekdays([]);
		setTasksText(TASKS_TEXT_FEEDBACK);
		setVoice(DEF_VOICE);
		setRecording(RECORDING);
		setNegativeFeedback(NEGATIVE_FEEDBACK);
		setPositiveFeedback(POSITIVE_FEEDBACK);
	};

	useEffect(() => {
		setEmail(playSendValue);
		setLikeText(playLikeValue);
		setName(profile?.name ?? '');
		setUrl(profile?.url ?? '');
		setToken(profile?.token_access ?? '');
		setRetryCall(initRetryCalls);
		setTasksText(initTasksText);
		setPositiveFeedback(initPositiveFeedback);
		setNegativeFeedback(initNegativeFeedback);
		setCallTime(initCallTime);
		setRejectTime(initRejectTime);
		setRecording(initRecording);
		setStyleTime(initStyleTime);
		setStartDate(initStartDate);
		setWeekdays(initWeekdays);
		setVoice(voiceValue);
		setFeedbackEnable(initFeedback);
		// setOpenSet(true);
	}, [profile, open]);

	useEffect(() => {
		tasksText.length && negativeFeedback.length && positiveFeedback.length > 0
			? setPlayButton(false)
			: setPlayButton(true);
	}, [tasksText, negativeFeedback, positiveFeedback]);

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'name') {
			setName(evt.target.value);
		} else if (type === 'url') {
			setUrl(evt.target.value);
		} else if (type === 'token') {
			setToken(evt.target.value);
		} else if (type === 'tasksText') {
			setTasksText(evt.target.value);
		} else if (type === 'positiveFeedback') {
			setPositiveFeedback(evt.target.value);
		} else if (type === 'negativeFeedback') {
			setNegativeFeedback(evt.target.value);
		} else if (type === 'retryCall') {
			const value = evt.target.value;
			const zeroToFourCount = (value.match(/[0-4]/g) || []).length;
			if (
				(zeroToFourCount <= 1 &&
					/^\d$/.test(value) &&
					Number(value) >= 0 &&
					Number(value) <= 4) ||
				value === ''
			) {
				setRetryCall(value);
			}
		} else if (type === 'callTime') {
			setCallTime(evt.target.value);
		} else if (type === 'rejectTime') {
			setRejectTime(evt.target.value);
		} else if (type === 'styleTime') {
			setStyleTime(evt.target.value);
		}
	};

	const changeHandler = (evt: any, type: string) => {
		// console.log(evt.target.checked);
		const value = String(evt.target.checked);
		if (type === 'email') {
			setEmail(value);
		} else if (type === 'likeText') {
			setLikeText(value);
		} else if (type === 'recording') {
			setRecording(evt.target.checked);
		}
	};

	const handleUpdateProfile = async () => {
		if (
			name.length === 0 ||
			!name.trim() ||
			url.length === 0 ||
			!url.trim() ||
			token.length === 0 ||
			!token.trim() ||
			retryCall.length === 0 ||
			tasksText.length === 0 ||
			!tasksText.trim() ||
			positiveFeedback.length === 0 ||
			!positiveFeedback.trim() ||
			negativeFeedback.length === 0 ||
			!negativeFeedback.trim()
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}
		if (!re.test(callTime) || !re.test(rejectTime)) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Не правильный формат времени(должен быть вида HH:MM)',
				})
			);
		}
		if (styleTime === '0' && !checkValidDate(String(startDate))) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Некорректный формат даты',
				})
			);
		}
		// if (styleTime === '0' && !checkTimeAndDate()) {
		// 	return dispatch(
		// 		setErrorApp({
		// 			isErrorApp: true,
		// 			errorApp:
		// 				'Время начала не может быть раньше текущего времени',
		// 		})
		// 	);
		// }
		if (compareTime()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp:
						'Время начала не может быть позже времени окончания обзвона',
				})
			);
		}
		if (Number(retryCall) < 0 || Number(retryCall) > 4) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Количество повторных звонков от 0 до 4',
				})
			);
		}
		if (returnTextFromAutocall(tasksText).length > 500) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Длина текста обзвона не должна превышать 500 символов',
				})
			);
		}
		if (styleTime === '0' && startDate === null) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Поле дата должно быть заполнено',
				})
			);
		}
		if (styleTime === '2' && weekdays.length === 0) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо выбрать день недели',
				})
			);
		}
		if (feedbackEnable) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо выключить задачу на обзвон',
				})
			);
		}
		const response = await updateProfileEval(
			name,
			email,
			recording,
			likeText,
			url,
			profile!.first_text,
			profile!.second_text,
			profile!.third_text,
			profile!.fourth_text,
			profile!.confirm_text,
			profile!.like_text,
			profile!.last_text,
			retryCall,
			token,
			tasksText,
			negativeFeedback,
			positiveFeedback,
			voice,
			callTime,
			rejectTime,
			styleTime,
			weekdays,
			startDate
		);
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			setClose(true);
		}
	};

	// const handleTestSip = async () => {
	// 	if (
	// 		profile!.phone_shop === undefined ||
	// 		profile!.phone_shop.length <= 0 ||
	// 		profile!.phone_pass_shop === undefined ||
	// 		profile!.phone_pass_shop.length <= 0 ||
	// 		profile!.server_sip_shop === undefined ||
	// 		profile!.server_sip_shop.length <= 0
	// 	) {
	// 		return dispatch(
	// 			setErrorApp({
	// 				isErrorApp: true,
	// 				errorApp: 'Необходимо настроить сип-аккаунт',
	// 			})
	// 		);
	// 	}
	// 	const responseUpdateProfile = await updateProfile(
	// 		name,
	// 		listItems,
	// 		email,
	// 		recording,
	// 		likeText,
	// 		url,
	// 		profile!.first_text,
	// 		profile!.second_text,
	// 		profile!.third_text,
	// 		profile!.fourth_text,
	// 		profile!.confirm_text,
	// 		profile!.like_text,
	// 		profile!.last_text,
	// 		retryCall,
	// 		'',
	// 		// btoa(`${profile!.login}:${atob(passwordValue)}`),
	// 		bitrixIntegration,
	// 		tasksText,
	// 		negativeFeedback,
	// 		positiveFeedback,
	// 		voice
	// 	);
	// 	if (responseUpdateProfile.error) {
	// 		return dispatch(
	// 			setErrorApp({
	// 				isErrorApp: true,
	// 				errorApp: 'Произошла ошибка при обновление профиля',
	// 			})
	// 		);
	// 	} else {
	// 		const response = await setTestTask(
	// 			number,
	// 			orders,
	// 			testEmail,
	// 			phone,
	// 			voice,
	// 			// btoa(`${profile!.login}:${atob(passwordValue)}`)
	// 			' '
	// 		);
	// 		if (profile?.balance_shop === 0) {
	// 			dispatch(setBalanceModal({ show: true }));
	// 			return
	// 		}
	// 		if (response.error) {
	// 			const { msg } = response.error.response.data;
	// 			const textError = msg || 'Произошла ошибка при запуске тестовой задачи';
	// 			dispatch(
	// 				setErrorApp({
	// 					isErrorApp: true,
	// 					errorApp: textError,
	// 				})
	// 			);
	// 		} else {
	// 			dispatch(
	// 				setInfoApp({
	// 					isInfoApp: true,
	// 					infoApp:
	// 						'Сценарий тестирования подтверждения заказов успешно активирован',
	// 				})
	// 			);
	// 			dispatch(fetchProfile());
	// 			handleCloseSetings();
	// 		}
	// 	}
	// };

	const handleSelect = (evt: SelectChangeEvent<any>) => {
		setVoice(evt.target.value);
	};

	const re = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/;

	// const getStartDate = (): Date => {
	// 	const callTimeArr = callTime.split(':');
	// 	const newStartDate = new Date(startDate || '');
	// 	newStartDate.setHours(Number(callTimeArr[0]));
	// 	newStartDate.setMinutes(Number(callTimeArr[1]));
	// 	return newStartDate;
	// }

	const checkValidDate = (date: Date | string | number): boolean => {
		return new Date(date).toString() !== 'Invalid Date';
	};

	// const checkTimeAndDate = (): boolean => {
	// 	const now = new Date();
	// 	return getStartDate() > now;
	// }

	const checkTimeStart = (): boolean => {
		const now = new Date(String(startDate)).getDate();
		return now < new Date().getDate();
	};

	const returnTextFromAutocall = (text: string) => {
		return text.replace('<@name@>', '').replace('<@number@>', '');
	};

	const compareTime = () => {
		const callTimeArr = callTime.split(':');
		const rejectTimeArr = rejectTime.split(':');
		if (Number(callTimeArr[0]) < Number(rejectTimeArr[0])) {
			return false;
		} else if (
			Number(callTimeArr[0]) === Number(rejectTimeArr[0]) &&
			Number(callTimeArr[1]) < Number(rejectTimeArr[1])
		) {
			return false;
		} else {
			// console.log('Задачу не запускаем')
			return true;
		}
	};

	const setTask = async () => {
		// if (
		// 	profile!.phone_autocall === undefined ||
		// 	profile!.phone_autocall.length <= 0 ||
		// 	profile!.phone_pass_autocall === undefined ||
		// 	profile!.phone_pass_autocall.length <= 0 ||
		// 	profile!.server_sip_autocall === undefined ||
		// 	profile!.server_sip_autocall.length <= 0
		// ) {
		// 	return dispatch(
		// 		setErrorApp({
		// 			isErrorApp: true,
		// 			errorApp: 'Необходимо настроить сип-аккаунт',
		// 		})
		// 	);
		// }
		if (profile!.balance_shop === 0) {
			dispatch(setBalanceModal({ show: true }));
			return;
		}
		if (returnTextFromAutocall(tasksText).length > 500) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Длина текста обзвона не должна превышать 500 символов',
				})
			);
		}
		if (styleTime === '0' && startDate === null) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Поле дата должно быть заполнено',
				})
			);
		}
		if (styleTime === '0' && !checkValidDate(String(startDate))) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Некорректный формат даты',
				})
			);
		}
		// if (styleTime === '0' && !checkTimeAndDate()) {
		// 	return dispatch(
		// 		setErrorApp({
		// 			isErrorApp: true,
		// 			errorApp:
		// 				'Время начала не может быть раньше текущего времени',
		// 		})
		// 	);
		// }
		// if (styleTime === '0' && !checkStartMinutes()) {
		// 	return dispatch(
		// 		setErrorApp({
		// 			isErrorApp: true,
		// 			errorApp:
		// 				'Время начала обзвона меньше чем через 5 минут',
		// 		})
		// 	);
		// }
		if (!feedbackEnable && styleTime === '0' && checkTimeStart()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Введите актульную дату',
				})
			);
		}

		if (styleTime === '2' && weekdays.length === 0) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо выбрать день недели',
				})
			);
		}
		if (!re.test(callTime) || !re.test(rejectTime)) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Не правильный формат времени(должен быть вида HH:MM)',
				})
			);
		}
		if (compareTime()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp:
						'Время начала не может быть позже времени окончания обзвона',
				})
			);
		}
		if (Number(retryCall) < 0 || Number(retryCall) > 4) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Количество повторных звонков от 0 до 4',
				})
			);
		}
		if (retryCall.length === 0 || tasksText.length === 0 || !tasksText.trim()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}
		const responseUpdate = await updateProfileEval(
			name,
			email,
			recording,
			likeText,
			url,
			profile!.first_text,
			profile!.second_text,
			profile!.third_text,
			profile!.fourth_text,
			profile!.confirm_text,
			profile!.like_text,
			profile!.last_text,
			retryCall,
			token,
			tasksText,
			negativeFeedback,
			positiveFeedback,
			voice,
			callTime,
			rejectTime,
			styleTime,
			weekdays,
			startDate
		);
		if (responseUpdate.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			setClose(true);
		}

		const response = await feedback();
		if (response.error) {
			const { msg } = response.error.response.data;
			const textError = msg || 'Произошла ошибка при запуске обзвона';
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: textError,
				})
			);
		} else {
			const { enable } = response.data.data;
			if (enable === true) {
				dispatch(
					setInfoApp({
						isInfoApp: true,
						infoApp: 'Обзвон оценки качества обслуживания успешно активирован!',
					})
				);
				dispatch(fetchProfile());
			} else if (enable === false) {
				dispatch(
					setInfoApp({
						isInfoApp: true,
						infoApp: 'Обзвон оценки качества обслуживания успешно выключен!',
					})
				);
				dispatch(fetchProfile());
			}
			setClose(true);
		}
	};

	const handleChangeDay = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.target.checked
			? setWeekdays([...weekdays, evt.target.name])
			: setWeekdays(
				weekdays.filter((day) => {
					return day !== evt.target.name;
				})
			);
	};

	return ReactDOM.createPortal(
		<Dialog scroll="body" open={open} onClose={() => setClose(true)}>
			<DialogTitle className={styles.profile_label}>
				Настройки оценки обслуживания
				<button onClick={() => setClose(true)} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div className={styles.container}>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel1'}
						onChange={handleChangeAccordion('panel1')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Подключение магазина</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								margin="dense"
								id="new_name"
								label="Наименование магазина"
								fullWidth
								variant="standard"
								defaultValue={profile?.name}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'name')
								}
								error={name.length <= 0 || !name.trim()}
							/>
							<TextField
								margin="dense"
								id="new_url"
								label="URL обработчика статусов заказов"
								fullWidth
								variant="standard"
								defaultValue={profile?.url}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'url')
								}
								error={url.length <= 0 || !url.trim()}
							/>
							<TextField
								margin="dense"
								id="token_access"
								label="Токен доступа"
								fullWidth
								variant="standard"
								defaultValue={profile?.token_access}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'token')
								}
								error={token.length <= 0 || !token.trim()}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel2'}
						onChange={handleChangeAccordion('panel2')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Сценарий</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								margin="dense"
								id="tasksText"
								label="Текст (используйте <@name@>, <@number@> для включения данных в текст)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={tasksText}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'tasksText')
								}
								error={tasksText.length <= 0 || !tasksText.trim()}
							/>
							<TextField
								margin="dense"
								id="negativeFeedback"
								label="Оценка отрицательная (от 1 до 3)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={negativeFeedback}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'negativeFeedback')
								}
								error={negativeFeedback.length <= 0 || !negativeFeedback.trim()}
							/>
							<TextField
								margin="dense"
								id="positiveFeedback"
								label="Оценка положительная (от 4 до 5)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={positiveFeedback}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'positiveFeedback')
								}
								error={positiveFeedback.length <= 0 || !positiveFeedback.trim()}
							/>
							<InputLabel
								className={styles.voice_input}
								id="demo-simple-select-label"
							>
								Выбор голоса
							</InputLabel>
							<Select
								className={`${styles.voice_select} change-input-outlined`}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								defaultValue={voice}
								onChange={handleSelect}
							>
								<MenuItem value={'jane'}>jane</MenuItem>
								<MenuItem value={'oksana'}>oksana</MenuItem>
								<MenuItem value={'omazh'}>omazh</MenuItem>
								<MenuItem value={'zahar'}>zahar</MenuItem>
								<MenuItem value={'ermil'}>ermil</MenuItem>
								<MenuItem value={'marina'}>marina</MenuItem>
								<MenuItem value={'lera'}>lera</MenuItem>
								<MenuItem value={'alexander'}>alexander</MenuItem>
								<MenuItem value={'anton'}>anton</MenuItem>
							</Select>
							<PlayTextButton
								voice={voice}
								text={
									tasksText + '' + '' + negativeFeedback + '' + positiveFeedback
								}
								disabled={disabledPlayButton}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel3'}
						onChange={handleChangeAccordion('panel3')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Дополнительные настройки</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								inputProps={{ inputMode: 'numeric', min: 0, max: 4 }}
								margin="dense"
								id="retry_call_shop_feedback"
								label="Повторных звонков"
								fullWidth
								variant="standard"
								value={retryCall}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'retryCall')
								}
								error={retryCall.length <= 0 || !retryCall.trim()}
							/>
							<TextField
								margin="dense"
								id="calltime"
								label="Время начала"
								fullWidth
								variant="standard"
								value={callTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'callTime')
								}
								error={callTime.length <= 0 || !callTime.trim()}
							/>
							<TextField
								margin="dense"
								id="rejectTime"
								label="Прекратить обзвон"
								fullWidth
								variant="standard"
								value={rejectTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'rejectTime')
								}
								error={rejectTime.length <= 0 || !rejectTime.trim()}
							/>
							<FormControl component="fieldset">
								<FormLabel component="legend">Дата</FormLabel>
								<RadioGroup
									row
									aria-label="type_input"
									name="row-radio-buttons-group"
									value={styleTime}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'styleTime')
									}
								>
									<FormControlLabel
										value="0"
										control={<Radio />}
										label="День"
									/>
									<FormControlLabel
										value="1"
										control={<Radio />}
										label="Каждый день"
									/>
									<FormControlLabel
										value="2"
										control={<Radio />}
										label="По дням недели"
									/>
								</RadioGroup>
							</FormControl>
							<div
								className={`${styles.wrapper_for_datarange} change-input-outlined-datapicker`}
							>
								{styleTime === '0' ? (
									<LocalizationProvider
										locale={ruLocale}
										dateAdapter={AdapterDateFns}
									>
										<DatePicker
											mask={'__.__.____'}
											label="Выберите дату"
											value={startDate}
											onChange={(newValue: any) => {
												setStartDate(newValue);
											}}
											renderInput={(params: any) => (
												<TextField
													{...params}
													helperText={null}
													error={
														new Date(String(startDate)).getDate() <
														new Date().getDate()
													}
												/>
											)}
										/>
									</LocalizationProvider>
								) : null}
								{styleTime === '2' ? (
									<FormControl component="fieldset" variant="standard">
										<FormLabel component="legend">
											Выберите дни недели
										</FormLabel>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('1')}
														name="1"
													/>
												}
												label="Понедельник"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('2')}
														name="2"
													/>
												}
												label="Вторник"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('3')}
														name="3"
													/>
												}
												label="Среда"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('4')}
														name="4"
													/>
												}
												label="Четверг"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('5')}
														name="5"
													/>
												}
												label="Пятница"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('6')}
														name="6"
													/>
												}
												label="Суббота"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('0')}
														name="0"
													/>
												}
												label="Воскресенье"
											/>
										</FormGroup>
									</FormControl>
								) : null}
							</div>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'recording')
									}
									checked={recording}
									label="Включить запись (Внимание! При включении этой опции, в начало обзвона вставляется предупреждение клиента о записи звонка.)"
									labelPlacement="end"
								/>
							</FormGroup>
						</AccordionDetails>
					</Accordion>
				</div>
				<DialogActions className={styles.dialogActions}>
					<Button onClick={() => setClose(true)}>Закрыть</Button>
					<Tooltip
						placement="top"
						title={
							feedbackEnable
								? 'Выключите обзвон, чтобы сбросить настройки'
								: 'Сбросить настройки'
						}
					>
						<span>
							<Button disabled={feedbackEnable} onClick={setDefaultSettings}>
								Сбросить настройки
							</Button>
						</span>
					</Tooltip>
					<Tooltip
						placement="top"
						title={
							feedbackEnable
								? 'Выключите обзвон, чтобы сохранить'
								: callTime === initCallTime &&
									retryCall === initRetryCalls &&
									rejectTime === initRejectTime &&
									styleTime === initStyleTime &&
									voice === profile?.voice_feedback &&
									(weekdays === initWeekdays() || profile?.weekdays === null) &&
									tasksText === initTasksText() &&
									recording === initRecording() &&
									moment(new Date(startDate ?? 0)).format('MM-DD-YYYY') ===
									moment(initStartDate()).format('MM-DD-YYYY')
									? 'Внесите изменения, чтобы сохранить'
									: 'Сохранить'
						}
					>
						<span>
							<Button
								disabled={
									// gmt === initGMT() &&
									(callTime === initCallTime &&
										retryCall === initRetryCalls &&
										rejectTime === initRejectTime &&
										styleTime === initStyleTime &&
										voice === profile?.voice_feedback &&
										(weekdays === initWeekdays() ||
											profile?.weekdays === null) &&
										tasksText === initTasksText() &&
										recording === initRecording() &&
										moment(new Date(startDate ?? 0)).format('MM-DD-YYYY') ===
										moment(initStartDate()).format('MM-DD-YYYY')) ||
									feedbackEnable
								}
								onClick={handleUpdateProfile}
							>
								Сохранить
							</Button>
						</span>
					</Tooltip>
					<Button
						onClick={setTask}
					// disabled={sending}
					>
						{feedbackEnable ? 'Выключить' : 'Активировать'}
					</Button>
				</DialogActions>
			</div>
		</Dialog>,
		document.getElementById('evaluation')!
	);
};

export default SettingsEvalForm;
