import axios from 'axios';
import resolve from './resolve';
import { IProfile } from '../models/IProfile';
import { API_PREFIX } from '../utils/const';

interface testSipParams {
	lines_shop: string;
	phone_shop: string;
	phone_pass_shop: string;
	server_sip_shop: string;
}
interface testSipResp {
	statusSip: string;
	timeUpdateSip: string;
}

export async function testSip({
	lines_shop,
	phone_shop,
	phone_pass_shop,
	server_sip_shop,
}: testSipParams): Promise<any> {
	return await resolve(
		/* test_sip */
		axios.patch<testSipResp>(`${API_PREFIX}/users`, {
			withCredentials: true,
			lines_shop: lines_shop,
			phone_shop: phone_shop,
			phone_pass_shop: phone_pass_shop,
			server_sip_shop: server_sip_shop,
		})
	);
}

export async function changeSip({
	lines_shop,
	phone_shop,
	phone_pass_shop,
	server_sip_shop,
}: testSipParams): Promise<any> {
	return await resolve(
		/* change_sip */
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			lines_shop: lines_shop,
			phone_shop: phone_shop,
			phone_pass_shop: phone_pass_shop,
			server_sip_shop: server_sip_shop,
		})
	);
}

export async function activateLicence(key: string): Promise<any> {
	return await resolve(
		/* activate_licence */
		axios.get(`${API_PREFIX}/users/key?key=${key}&lic_type=4`, {
			withCredentials: true,
		})
	);
}

export async function activateVideoLicence(key: string): Promise<any> {
	return await resolve(
		/* activate_video_licence */
		axios.get(`${API_PREFIX}/users/key?key=${key}&lic_type=5`, {
			withCredentials: true,
		})
	);
}

export async function updateProfileEval(
	name: string,
	play_send_email: string,
	recording_feedback: boolean,
	play_like_text: string,
	url: string,
	first_text: string,
	second_text: string,
	third_text: string,
	fourth_text: string,
	confirm_text: string,
	like_text: string,
	last_text: string,
	retry_call_shop_feedback: string,
	token_access: string,
	tasks_text_feedback: string,
	negative_feedback: string,
	positive_feedback: string,
	voice_feedback: string,
	callTime: string,
	rejectTime: string,
	styleTime: string,
	weekdays: string[],
	startDate: string | Date | null
): Promise<any> {
	return await resolve(
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			name: name,
			play_send_email: play_send_email,
			recording_feedback: recording_feedback,
			play_like_text: play_like_text,
			url: url,
			first_text: first_text,
			second_text: second_text,
			third_text: third_text,
			fourth_text: fourth_text,
			confirm_text: confirm_text,
			like_text: like_text,
			last_text: last_text,
			retry_call_shop_feedback: retry_call_shop_feedback,
			token_access: token_access,
			tasks_text_feedback: tasks_text_feedback,
			negative_feedback: negative_feedback,
			positive_feedback: positive_feedback,
			voice_feedback: voice_feedback,
			callTime: callTime,
			rejectTime: rejectTime,
			styleTime: styleTime,
			weekdays: weekdays,
			startDate: startDate,
		})
	);
}

export async function updateProfileVoiceMails(
	enable_voicemail: boolean,
	name_voicemail: string,
	url_voicemail: string,
	token_access_voicemail: string,
	tasks_text_voicemail: string,
	voice_voicemail: string,
	retry_call_shop_voicemail: string,
	callTime_voicemail: string,
	rejectTime_voicemail: string,
	styleTime_voicemail: string,
	startDate_voicemail: string | Date | null,
	weekdays_voicemail: string[]
): Promise<any> {
	return await resolve(
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			enable_voicemail: enable_voicemail,
			name_voicemail: name_voicemail,
			url_voicemail: url_voicemail,
			token_access_voicemail: token_access_voicemail,
			tasks_text_voicemail: tasks_text_voicemail,
			voice_voicemail: voice_voicemail,
			retry_call_shop_voicemail: retry_call_shop_voicemail,
			callTime_voicemail: callTime_voicemail,
			rejectTime_voicemail: rejectTime_voicemail,
			styleTime_voicemail: styleTime_voicemail,
			startDate_voicemail: startDate_voicemail,
			weekdays_voicemail: weekdays_voicemail
		})
	);
}

export async function updateProfile(
	name: string,
	play_send_email: string,
	recording_order: boolean,
	play_like_text: string,
	url: string,
	first_text: string,
	second_text: string,
	third_text: string,
	fourth_text: string,
	confirm_text: string,
	like_text: string,
	last_text: string,
	retry_call_shop: string,
	token_access: string,
	tasks_text_order: string,
	order_confirmed: string,
	order_cancelled: string,
	voice_order: string,
	enable_order_confirmation: boolean
): Promise<any> {
	return await resolve(
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			name: name,
			play_send_email: play_send_email,
			recording_order: recording_order,
			play_like_text: play_like_text,
			url: url,
			first_text: first_text,
			second_text: second_text,
			third_text: third_text,
			fourth_text: fourth_text,
			confirm_text: confirm_text,
			like_text: like_text,
			last_text: last_text,
			retry_call_shop: retry_call_shop,
			token_access: token_access,
			tasks_text_order: tasks_text_order,
			order_cancelled: order_cancelled,
			order_confirmed: order_confirmed,
			voice_order: voice_order,
			enable_order_confirmation,
		})
	);
}

export async function setTestTask(
	number: string,
	orders: string,
	email: string,
	phone: string,
	voice: string,
	token: string
): Promise<any> {
	return await resolve(
		/* tasks */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			number: number,
			orders: orders,
			email: email,
			phone: phone,
			voice: voice,
			token: token,
		})
	);
}

export async function changePassword(password: string): Promise<any> {
	return await resolve(
		/* change_password */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			password: password,
		})
	);
}

export async function changeDisplayName(display_name: string): Promise<any> {
	return await resolve(
		/* change_name */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			display_name: display_name,
		})
	);
}

export async function changeApiKey(apiKey: string): Promise<any> {
	return await resolve(
		/* change_apiKey */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			apiKey: apiKey,
		})
	);
}

export async function setWarningBalance(warning: boolean): Promise<any> {
	return await resolve(
		/* message_balance */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			warning: warning,
		})
	);
}

export async function getProfile(): Promise<any> {
	return await resolve(
		axios.get(`${API_PREFIX}/users`, {
			withCredentials: true,
		})
	);
}

export async function feedback(): Promise<any> {
	return await resolve(
		axios.get(`${API_PREFIX}/users/startFeedback`, {
			withCredentials: true,
		})
	);
}
