import React, { FC, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styles from './SettingsForm.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { updateProfile } from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import TestForm from '../TestForm/TestForm';
import ApiForm from '../ApiForm/ApiForm';
import PlayTextButton from '../PlayTextButton/PlayTextButton';
import {
	DEF_VOICE,
	ORDER_CANCELLED,
	ORDER_CONFIRMED,
	RETRY_CALLS,
	TASKS_TEXT,
} from '../../utils/const';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import ReactDOM from 'react-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SettingsFormsProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};

const SettingsForm: FC<SettingsFormsProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const recordValue: boolean =
		typeof profile?.recording_order === 'boolean'
			? profile?.recording_order
			: false;
	const enableOrderConfirmationValue: boolean =
		typeof profile?.enable_order_confirmation === 'boolean'
			? profile?.enable_order_confirmation
			: false;

	const voiceValue: string =
		typeof profile?.voice_order === 'string' ? profile?.voice_order : DEF_VOICE;

	const scriptVoice: string =
		typeof profile?.voice === 'string' ? profile?.voice : DEF_VOICE;

	const initTasksText: string =
		typeof profile?.tasks_text_order === 'string'
			? `${profile?.tasks_text_order}`
			: TASKS_TEXT;

	const initOrderСonfirmed: string =
		typeof profile?.order_confirmed === 'string'
			? profile?.order_confirmed
			: ORDER_CONFIRMED;

	const initOrderСancelled: string =
		typeof profile?.order_cancelled === 'string'
			? profile?.order_cancelled
			: ORDER_CANCELLED;

	const retryCallValue: string =
		typeof profile?.retry_call_shop === 'string'
			? `${profile?.retry_call_shop}`
			: RETRY_CALLS;

	const playListValue: string =
		typeof profile?.play_list_items === 'boolean'
			? `${profile?.play_list_items}`
			: 'false';

	const playSendValue: string =
		typeof profile?.play_send_email === 'boolean'
			? `${profile?.play_send_email}`
			: 'false';

	const playLikeValue: string =
		typeof profile?.play_like_text === 'boolean'
			? `${profile?.play_like_text}`
			: 'false';

	const [name, setName] = useState<string>(profile?.name || '');
	const [tasksText, setTasksText] = useState<string>(initTasksText);
	const [orderСonfirmed, setOrderСonfirmed] =
		useState<string>(initOrderСonfirmed);
	const [orderСancelled, setOrderСancelled] =
		useState<string>(initOrderСancelled);
	const [url, setUrl] = useState<string>(profile?.url || '');
	const [token, setToken] = useState<string>(profile?.token_access || '');
	const [retryCall, setRetryCall] = useState<string>(retryCallValue);
	const [listItems, setListItems] = useState<string>(playListValue);
	const [email, setEmail] = useState<string>(playSendValue);
	const [likeText, setLikeText] = useState<string>(playLikeValue);
	const [record, setRecord] = useState<boolean>(recordValue);
	const [enableOrderConfirmation, setEnableOrderConfirmation] =
		useState<boolean>(enableOrderConfirmationValue);
	const [number, setNumber] = useState<string>('');
	const [orders, setOrders] = useState<string>('');
	const [testEmail, setTestEmail] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [voice, setVoice] = useState<string>(voiceValue);
	const [testScriptVoice, setTestScriptVoice] = useState<string>(scriptVoice);
	const [disabledPlayButton, setPlayButton] = useState<boolean>(true);
	const [disabledPlayButtonOrder, setPlayButtonOrder] = useState<boolean>(true);
	const dispatch = useAppDispatch();
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChangeAccordion =
		(panel: string) => (evt: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	const handleCloseSetings = () => {
		setNumber('');
		setOrders('');
		setTestEmail('');
		setPhone('');
		setClose(false);
		// setVoice('');
	};

	useEffect(() => {
		setListItems(playListValue);
		setEmail(playSendValue);
		setLikeText(playLikeValue);
		setRecord(recordValue);
		setEnableOrderConfirmation(enableOrderConfirmationValue);
		setName(profile?.name ?? '');
		setUrl(profile?.url ?? '');
		setToken(profile?.token_access ?? '');
		setRetryCall(retryCallValue);
		setTasksText(initTasksText);
		setOrderСonfirmed(initOrderСonfirmed);
		setOrderСancelled(initOrderСancelled);
		setVoice(voiceValue);
		setTestScriptVoice(scriptVoice);
		// setOpenSet(true);
	}, [profile, open]);

	useEffect(() => {
		number.length > 0 &&
		orders.length > 0 &&
		testEmail.length > 0 &&
		phone.length > 0 &&
		name.length > 0
			? setPlayButton(false)
			: setPlayButton(true);
	}, [number, orders, testEmail, phone, voice, name]);

	useEffect(() => {
		tasksText.length && orderСancelled.length && orderСonfirmed.length > 0
			? setPlayButtonOrder(false)
			: setPlayButtonOrder(true);
	}, [tasksText, orderСonfirmed, orderСancelled]);

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'name') {
			setName(evt.target.value.trim());
		} else if (type === 'url') {
			setUrl(evt.target.value.trim());
		} else if (type === 'token') {
			setToken(evt.target.value);
		} else if (type === 'tasksText') {
			setTasksText(evt.target.value);
		} else if (type === 'orderСonfirmed') {
			setOrderСonfirmed(evt.target.value);
		} else if (type === 'orderСancelled') {
			setOrderСancelled(evt.target.value);
		} else if (type === 'retryCall') {
			const value = evt.target.value;
			const zeroToFourCount = (value.match(/[0-4]/g) || []).length;
			if (
				(zeroToFourCount <= 1 &&
					/^\d$/.test(value) &&
					Number(value) >= 0 &&
					Number(value) <= 4) ||
				value === ''
			) {
				setRetryCall(value);
			}
		}
	};

	const updateTestData = (key: string, value: string) => {
		if (key === 'number') {
			setNumber(value);
		} else if (key === 'orders') {
			setOrders(value);
		} else if (key === 'testEmail') {
			setTestEmail(value);
		} else if (key === 'phone') {
			setPhone(value);
		} else if (key === 'voice') {
			setTestScriptVoice(value);
		}
	};

	const changeHandler = (evt: any, type: string) => {
		// console.log(evt.target.checked);
		const value = String(evt.target.checked);
		if (type === 'listItems') {
			setListItems(value);
		} else if (type === 'email') {
			setEmail(value);
		} else if (type === 'likeText') {
			setLikeText(value);
		} else if (type === 'record') {
			setRecord(evt.target.checked);
		} else if (type === 'enable_order_confirmation') {
			setEnableOrderConfirmation(evt.target.checked);
		}
	};

	const handleUpdateProfile = async () => {
		if (
			name.length === 0 ||
			!name.trim() ||
			url.length === 0 ||
			!url.trim() ||
			token.length === 0 ||
			!token.trim() ||
			retryCall.length === 0 ||
			tasksText.length === 0 ||
			!tasksText.trim() ||
			orderСonfirmed.length === 0 ||
			!orderСonfirmed.trim() ||
			orderСancelled.length === 0 ||
			!orderСancelled.trim()
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}
		const response = await updateProfile(
			name,
			email,
			record,
			likeText,
			url,
			profile!.first_text,
			profile!.second_text,
			profile!.third_text,
			profile!.fourth_text,
			profile!.confirm_text,
			profile!.like_text,
			profile!.last_text,
			retryCall,
			token,
			tasksText,
			orderСonfirmed,
			orderСancelled,
			voice,
			enableOrderConfirmation
		);
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			handleCloseSetings();
		}
	};

	const returnTextScript = () => {
		const recordText = 'Внимание, Ваш разговор записывается';
		const helloText = `Здравствуйте это интернет-магазин ${name}.`;
		const numberText = `Хотим подтвердить ваш заказ ${number}`;
		const ordersText = orders;
		const emailText = `Счет на оплату отправлен на ${testEmail}`;
		const requestText = `Для подтверждения заказа скажите д+а для отмены заказа скажите нет.`;
		const confirmText = `Спасибо, ваш заказ подтвержден!`;
		const likeMessage = `Если вам понравилось качество обслуживания, скажите д+а, если нет - оставьте сообщение`;
		return `${record ? recordText : ''}. ${helloText}. ${numberText}. ${
			listItems === 'true' ? '' : ordersText
		}. ${email === 'true' ? '' : emailText}.
		${requestText}. ${confirmText}. ${likeText === 'true' ? '' : likeMessage}`;
	};

	// const passwordValue: string = Object.prototype.hasOwnProperty.call(
	// 	profile,
	// 	'encryptedPassword_shop'
	// )
	// 	? profile!.encryptedPassword_shop!
	// 	: profile!.encryptedPassword;

	const handleSelect = (evt: SelectChangeEvent<any>) => {
		setVoice(evt.target.value);
	};

	return ReactDOM.createPortal(
		<Dialog scroll="body" open={open} onClose={handleCloseSetings}>
			<DialogTitle className={styles.profile_label}>
				Настройки подтверждения заказов
				<button onClick={handleCloseSetings} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div>
				<div className={styles.container}>
					<div className={styles.wrapper_for_block}>
						<DialogContent className={styles.dialog_content}>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'enable_order_confirmation')
									}
									checked={enableOrderConfirmation}
									label="Активировать сервис подтверждения заказов"
									labelPlacement="end"
								/>
							</FormGroup>
						</DialogContent>
					</div>
					<div className={styles.wrapper_for_block}>
						<Accordion
							sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
							expanded={expanded === 'panel1'}
							onChange={handleChangeAccordion('panel1')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>Подключение магазина</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TextField
									margin="dense"
									id="new_name"
									label="Наименование магазина"
									fullWidth
									variant="standard"
									defaultValue={profile?.name}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'name')
									}
									error={name.length <= 0 || !name.trim()}
								/>
								<TextField
									margin="dense"
									id="new_url"
									label="URL обработчика статусов заказов"
									fullWidth
									variant="standard"
									defaultValue={profile?.url}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'url')
									}
									error={url.length <= 0 || !url.trim()}
								/>
								<TextField
									margin="dense"
									id="token_access"
									label="Токен доступа"
									fullWidth
									variant="standard"
									defaultValue={profile?.token_access}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'token')
									}
									error={token.length <= 0 || !token.trim()}
								/>
								<ApiForm
									number={number}
									orders={orders}
									email={testEmail}
									phone={phone}
									voice={voice}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
					<div className={styles.wrapper_for_block}>
						<Accordion
							sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
							expanded={expanded === 'panel2'}
							onChange={handleChangeAccordion('panel2')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>Сценарий</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TextField
									margin="dense"
									id="tasksText"
									label="Текст (используйте <@name@>, <@number@>, <@email@> для включения данных в текст)"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={tasksText}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'tasksText')
									}
									error={tasksText.length <= 0 || !tasksText.trim()}
								/>
								<TextField
									margin="dense"
									id="orderСancelled"
									label="Отменен"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={orderСancelled}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'orderСancelled')
									}
									error={orderСancelled.length <= 0 || !orderСancelled.trim()}
								/>
								<TextField
									margin="dense"
									id="orderСonfirmed"
									label="Подвержден"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={orderСonfirmed}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'orderСonfirmed')
									}
									error={orderСonfirmed.length <= 0 || !orderСonfirmed.trim()}
								/>
								<InputLabel
									className={styles.voice_input}
									id="demo-simple-select-label"
								>
									Выбор голоса
								</InputLabel>
								<Select
									className={`${styles.voice_select} change-input-outlined`}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									defaultValue={voice}
									onChange={handleSelect}
								>
									<MenuItem value={'jane'}>jane</MenuItem>
									<MenuItem value={'oksana'}>oksana</MenuItem>
									<MenuItem value={'omazh'}>omazh</MenuItem>
									<MenuItem value={'zahar'}>zahar</MenuItem>
									<MenuItem value={'ermil'}>ermil</MenuItem>
									<MenuItem value={'marina'}>marina</MenuItem>
									<MenuItem value={'lera'}>lera</MenuItem>
									<MenuItem value={'alexander'}>alexander</MenuItem>
									<MenuItem value={'anton'}>anton</MenuItem>
								</Select>
								<PlayTextButton
									voice={voice}
									text={
										tasksText + '' + '' + orderСancelled + '' + orderСonfirmed
									}
									disabled={disabledPlayButtonOrder}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
					<div className={styles.wrapper_for_block}>
						<Accordion
							sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
							expanded={expanded === 'panel3'}
							onChange={handleChangeAccordion('panel3')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>Дополнительные настройки</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TextField
									inputProps={{ inputMode: 'numeric', min: 0, max: 4 }}
									margin="dense"
									id="retry_call_shop"
									label="Повторных звонков"
									fullWidth
									variant="standard"
									value={retryCall}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'retryCall')
									}
									error={retryCall.length <= 0 || !retryCall.trim()}
								/>
								<FormGroup>
									{/* <FormControlLabel
										control={<Checkbox />}
										onChange={(evt: React.SyntheticEvent<any>) =>
											changeHandler(evt, 'email')
										}
										checked={email === 'true'}
										label="Не воспроизводить фразу об уведомлении на почту"
										labelPlacement="end"
									/> */}
									<FormControlLabel
										control={<Checkbox />}
										onChange={(evt: React.SyntheticEvent<any>) =>
											changeHandler(evt, 'likeText')
										}
										checked={likeText === 'true'}
										label="Не воспроизводить оценку качества"
										labelPlacement="end"
									/>
									<FormControlLabel
										control={<Checkbox />}
										onChange={(evt: React.SyntheticEvent<any>) =>
											changeHandler(evt, 'record')
										}
										checked={record}
										label="Включить запись (Внимание! При включении этой опции, в начало обзвона вставляется предупреждение клиента о записи звонка.)"
										labelPlacement="end"
									/>
								</FormGroup>
							</AccordionDetails>
						</Accordion>
					</div>
					<div className={styles.wrapper_for_block}>
						<Accordion
							sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
							expanded={expanded === 'panel4'}
							onChange={handleChangeAccordion('panel4')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>Данные для тестирования сценария</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TestForm updateTestData={updateTestData} />
								<PlayTextButton
									voice={testScriptVoice}
									text={returnTextScript().trim()}
									disabled={disabledPlayButton}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
					<DialogActions className={styles.dialogActions}>
						<Button
							disabled={
								enableOrderConfirmation === enableOrderConfirmationValue &&
								name === profile?.name &&
								url === profile?.url &&
								token === profile?.token_access &&
								tasksText === initTasksText &&
								orderСancelled === initOrderСancelled &&
								orderСonfirmed === initOrderСonfirmed &&
								voice === voiceValue &&
								retryCall === retryCallValue &&
								email === playSendValue &&
								likeText === playLikeValue &&
								listItems === playListValue &&
								record === recordValue
							}
							onClick={handleUpdateProfile}
						>
							Сохранить
						</Button>
					</DialogActions>
					<DialogActions>
						{/*<Button onClick={onActivateLicence}>Активировать ключ</Button>*/}
					</DialogActions>
				</div>
			</div>
		</Dialog>,
		document.getElementById('settingsForm')!
	);
};

export default SettingsForm;
